import {useEffect} from 'react'

export default function useDefault() {
  const listener = event => event.preventDefault()
  useEffect(() => {
    document.addEventListener('contextmenu', listener)
    return () => {
      document.removeEventListener('contextmenu', listener)
    }
  }, [])
}
