export const PHOTO_LIGHT_VALUE = 45

export const sliderProperties = {
  duration: 3000,
  transitionDuration: 500,
  infinite: true,
  indicators: false,
  autoplay: true,
  arrows: false,
  pauseOnHover: false,
  onChange: (oldIndex, newIndex) => {
    // console.log(`slide transition from ${oldIndex} to ${newIndex}`);
  }
}
