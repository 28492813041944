import React from 'react'
import {isMobileDevice, isTabletDevice} from '../../shared/utils'
import AlbumsListDesktop from './desktop'
import AlbumsListMobile from './mobile'

const isMobile = isMobileDevice() && !isTabletDevice()

const AlbumsList = (props) => {
  const Component = isMobile ? AlbumsListMobile : AlbumsListDesktop
  return <Component {...props} />
}

export default AlbumsList
