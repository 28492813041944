import React from 'react'
import {Switch, Route} from 'react-router-dom'
import Home from './pages/home'
import Album from './pages/album'

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/album/:albumId">
        <Album />
      </Route>
    </Switch>
  )
}
