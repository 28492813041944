import {useEffect} from "react";
import Hammer from "hammerjs";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  element = document.body,
  onSwipeLeft,
  onSwipeRight,
}) => {
  useEffect(() => {
    const hammer = new Hammer(element)

    onSwipeLeft && hammer.on('swipeleft', onSwipeLeft)
    onSwipeRight && hammer.on('swiperight', onSwipeRight)

    return () => {
      onSwipeLeft && hammer.off('swipeleft', onSwipeLeft)
      onSwipeRight && hammer.off('swiperight', onSwipeRight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
