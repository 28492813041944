import React, {memo} from 'react'
import classNames from 'classnames'
import css from './style.module.scss'
import Icon from './icon'
import Phone from './phone'

const Contacts = ({type, disabled}) => {
  if (disabled) return
  return (
    <div className={classNames(
      css.contacts,
      type === 'home' ? css.contactsHome : css.contactsInner
    )}>
      <div className={css.icons}>
        <Icon name="fb" />
        <Icon name="instagram" />
        <Icon name="viber" />
        <Icon name="telegram" />
        <Icon name="whatsapp" />
      </div>
      <Phone />
    </div>
  )
}

export default memo(Contacts)
