import React, {useCallback, useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {Animate} from 'react-simple-animate'
import classNames from 'classnames'
import Gallery from 'react-photo-gallery'
import useApi from '../../hooks/use-api'
import useDefault from '../../hooks/use-default'
import useIsMounted from '../../hooks/use-is-mounted'
import Fancybox from '../../components/fancybox'
import AlbumsList from '../../components/albums-list'
import {PHOTO_GRID_HEIGHT, PHOTO_GRID_MARGIN} from '../../config'
import {mapPhotosMain} from './utils'
import Contacts from '../../components/contacts'
import {
  ANIMATION_BEGIN,
  ANIMATION_DURATION,
  ANIMATION_EASE_TYPE,
  ANIMATION_END,
  ANIMATION_TIMEOUT,
  FANCYBOX_OPTIONS,
} from './constants'
import css from './style.module.scss'

const Album = () => {
  useDefault()
  const api = useApi()
  const location = useLocation()
  const isMounted = useIsMounted()
  const params = useParams() || {}
  const albumId = params.albumId

  const [albums, setAlbums] = useState([])
  const [photos, setPhotos] = useState([])
  const [loading, setLoading] = useState(true)

  const init = useCallback(async (albumId) => {
    setLoading(true)
    setAlbums(await api.album.list())
    setPhotos(mapPhotosMain(await api.photo.list(albumId)))
    setTimeout(() => setLoading(false), ANIMATION_TIMEOUT)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api.album, api.photo])

  useEffect(() => {
    if (!isMounted()) return
    albumId && init(albumId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key, albumId])

  return (
    <div
      className={css.container}
    >
      <AlbumsList
        albums={albums}
        albumId={albumId}
      />
      <Animate
        play={!loading}
        start={ANIMATION_BEGIN}
        end={ANIMATION_END}
        duration={ANIMATION_DURATION}
        easeType={ANIMATION_EASE_TYPE}
      >
        <Fancybox
          options={FANCYBOX_OPTIONS}
        >
          <div className={classNames(
            css.photos,
            loading && css.photosHidden
          )}>
            <Gallery
              photos={photos}
              margin={PHOTO_GRID_MARGIN}
              targetRowHeight={PHOTO_GRID_HEIGHT}
            />
          </div>
        </Fancybox>
      </Animate>
    </div>
  )
}

export default Album
