import fb from './assets/icon-fb.svg'
import instagram from './assets/icon-instagram.svg'
import viber from './assets/icon-viber.svg'
import whatsapp from './assets/icon-whatsapp.svg'
import telegram from './assets/icon-telegram.svg'

export const PHONE = {
  value: '+380505772451',
  text: '+38 050 577 24 51',
}

export const LINK = {
  fb: 'https://www.facebook.com/toyshiku/',
  instagram: 'https://www.instagram.com/toyshiku/',
  viber: `viber://chat?number=${PHONE.value}`,
  whatsapp: `https://wa.me/${PHONE.value}`,
  telegram: `https://t.me/${PHONE.value}`,
}

export const TEXT = {
  fb: 'Facebook',
  instagram: 'Instagram',
  viber: 'Viber',
  whatsapp: 'WhatsApp',
  telegram: 'Telegram',
}

export const ICON = {
  fb,
  instagram,
  viber,
  whatsapp,
  telegram,
}
