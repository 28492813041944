import React from 'react'
import {Link} from 'react-router-dom'
import classNames from 'classnames'
import {SLIDESHOW_ID} from '../../config'
import Contacts from '../contacts'
import logo from '../../assets/logo.png'
import css from './style.desktop.module.scss'

const AlbumsListDesktop = ({albums, albumId}) => {
  if (!albums.length) return <div />
  return (
    <div className={css.albumsListContainer}>
      <div className={css.logo}>
        <Link to={'/'}>
          <img src={logo} width="200" height="80" alt="TOYSHIKU" />
        </Link>
      </div>
      <div className={css.albumsList}>
        {albums.map(({_id: id, title}) => id !== SLIDESHOW_ID && (
          <Link
            key={id}
            to={`/album/${id}`}
            className={classNames(albumId === id && css.active)}
          >
            {title}
          </Link>
        ))}
      </div>
      <Contacts type="inner" />
    </div>
  )
}

export default AlbumsListDesktop
