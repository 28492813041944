export const IS_DEV = process.env.NODE_ENV !== 'production'
export const BASE_URL = process.env.REACT_APP_BASE_URL

// @TODO: Replace id based approach to the "album-name"
export const SLIDESHOW_ID = IS_DEV ? '620125eb45f1553196be967d' : '626199f5ad63a734bdf9acaf'

export const TITLE = 'TOYSHIKU'
export const PHOTO_GRID_MARGIN = 0
export const PHOTO_GRID_HEIGHT = 200

export const IMAGE_UPLOADS_DIR = 'uploads'
export const IMAGE_RESOLUTION = 'jpg'
export const IMAGE_PREVIEW_POSTFIX = 'preview'
export const IMAGE_PREFIX = `${BASE_URL}/${IMAGE_UPLOADS_DIR}/`

