import {useCallback, useEffect, useRef} from 'react'

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true

    return function cleanup() {
      isMounted.current = false
    }
  }, [])

  const checker = useCallback(() => (
    isMounted.current
  ), [])

  return checker
}
