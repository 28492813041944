export const arrToObj = (arr, keyField) => (
  arr.reduce((obj, item) => {
    obj[item[`${keyField}`.trim()]] = item
    return obj
  }, {})
)

export const isMobileDevice = () => typeof window.orientation !== 'undefined'

export const isTabletDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent)
}
