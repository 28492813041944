import React, {useCallback, useEffect, useState} from 'react'
import classNames from 'classnames'
import css from './style.module.scss'
import icon from './assets/icon-arrow-up.svg'

const START_VISIBLE_FROM = 300

// @TODO: Add TOP_ARROW icon
const ScrollUpButton = ({scrollTop}) => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = useCallback(() => {
    const scrollPosition = document.body.scrollTop || window.pageYOffset
    setVisible(scrollPosition > START_VISIBLE_FROM)
  }, [])

  useEffect(() => {
    document.addEventListener('scroll', toggleVisible)

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      document.removeEventListener('scroll', toggleVisible)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <button
      onClick={scrollTop}
      className={classNames(
        css.scrollUp,
        !visible && css.scrollUpHidden
      )}
    >
      <img src={icon} alt="" />
    </button>
  )
}

export default ScrollUpButton
