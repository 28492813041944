const IS_MOBILE = typeof window.orientation !== 'undefined'

/*
# FANCYBOX: Documentation & Examples:
  https://fancyapps.com/playground/vi
  https://fancyapps.com/docs/ui/fancybox/options/
  https://fancyapps.com/showcase
*/
export const FANCYBOX_OPTIONS = {
  Toolbar: true,
  animated: true,
  dragToClose: false,
  showClass: false,
  hideClass: false,
  closeButton: 'top',
  click: false,
  Image: {
    click: 'next'
  },
  Thumbs: {
    autoStart: !IS_MOBILE,
  },
  Carousel: {
    friction: IS_MOBILE ? 0.88 : 0, // disabled slide animation on desktop
    Panzoom: {
      touch: IS_MOBILE, // Disable touch guestures on desktop
    },
  },
}

export const ANIMATION_TIMEOUT = 100
export const ANIMATION_EASE_TYPE = 'cubic-bezier(0.445, 0.05, 0.55, 0.95)'
export const ANIMATION_TOP_OFFSET = '-200px'
export const ANIMATION_DURATION = '0.35'

export const ANIMATION_BEGIN = {
  opacity: 0,
  transform: `translateY(${ANIMATION_TOP_OFFSET})`
}

export const ANIMATION_END = {
  opacity: 1,
  transform: 'translateY(0)'
}
