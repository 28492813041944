import {BASE_URL} from '../config'

const URL_PREFIX = 'public'
const HEADERS_DEFAULT = {'content-type': 'application/json'}

const _ = ({
  url: _url,
  method = 'GET',
  body: _body,
  headers: _headers,
}) => {
  const body = _body && JSON.stringify(_body)
  const headers = {...HEADERS_DEFAULT, ..._headers}
  const url = `${BASE_URL}${_url}`
  const req = fetch(url, {method, headers, body})
  return req.then(res => res.json()).catch(err => err)
}

const http = ({
  get: url => _({method: 'GET', url}),
})

const connect = (type) => ({
  list: (id) => http.get(`/${URL_PREFIX}/${type}${id ? '/' + id : ''}`),
  get: (id) => http.get(`/${URL_PREFIX}/${type}/${id}`),
  first: () => http.get(`/${URL_PREFIX}/${type}/first`),
})

const useApi = () => ({
  http,
  album: connect('album'),
  photo: connect('photo')
})

export default useApi
