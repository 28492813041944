import React, {memo} from 'react'
import {ICON, TEXT, LINK} from './constants'
import css from './style.module.scss'

const Icon = ({name}) => {
  const text = TEXT[name]
  const icon = ICON[name]
  const link = LINK[name]
  if (!icon) return
  return (
    <div className={css.icon}>
      {!link && (
        <img
          src={icon}
          alt={text}
        />
      )}
      {link && (
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={icon}
            alt={text}
          />
        </a>
      )}
    </div>
  )
}

export default memo(Icon)
