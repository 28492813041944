import {IMAGE_PREVIEW_POSTFIX, IMAGE_RESOLUTION, IMAGE_PREFIX} from '../../config'

export const getPhotoPreview = filename => filename.replace(
  `.${IMAGE_RESOLUTION}`,
  `-${IMAGE_PREVIEW_POSTFIX}.${IMAGE_RESOLUTION}`
)

export const getPhotoInfo = filename => {
  const preview = `${IMAGE_PREFIX}${getPhotoPreview(filename)}`
  const full = `${IMAGE_PREFIX}${filename}`
  return {preview, full}
}

export const mapPhotosMain = photos => (
  photos.map(({filename, width, height}) => {
    const {preview, full} = getPhotoInfo(filename)
    return {
      width,
      height,
      loading: 'lazy',
      key: filename,
      src: preview,
      'data-src': full,
      'data-download-src': full,
      'data-fancybox': 'gallery'
    }
  })
)
