import React, {useCallback, useEffect, useState} from 'react'
import useApi from '../../hooks/use-api'
import {BASE_URL, SLIDESHOW_ID} from '../../config'
import {Link} from 'react-router-dom'
import {Fade} from 'react-slideshow-image'
import useDefault from '../../hooks/use-default'
import useIsMounted from '../../hooks/use-is-mounted'
import {PHOTO_LIGHT_VALUE, sliderProperties} from './constants'
import Contacts from '../../components/contacts'
import {arrToObj} from '../../shared/utils'
import 'react-slideshow-image/dist/styles.css'
import logo from '../../assets/logo.png'
import css from './style.module.scss'

const Slideshow = () => {
  useDefault()
  const albumId = SLIDESHOW_ID
  const api = useApi()
  const isMounted = useIsMounted()

  const [photos, setPhotos] = useState([])
  const [photosObj, setPhotosObj] = useState([])
  const [firstAlbumId, setFirstAlbumId] = useState([])

  const init = useCallback(async (albumId) => {
    setFirstAlbumId((await api.album.first())[0]._id)
    const photosArr = await api.photo.list(albumId)
    setPhotos(photosArr)
    setPhotosObj(arrToObj(photosArr, '_id'))
  }, [api.album, api.photo])

  // @TODO: Share this info with <Contacts /> component
  const isDarkImage = useCallback(id => (
    photosObj[id]?.brightness <= PHOTO_LIGHT_VALUE
  ), [photosObj])

  useEffect(() => {
    if (!isMounted()) return
    albumId && init(albumId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className={css.slideContainer}>
        <div className={css.logo}>
          <img src={logo} width="270" height="100" alt="TOYSHIKU" />
        </div>
        <Contacts type="home" />
        <Fade {...sliderProperties}>
          {photos.map(({_id: id, filename}) => (
            <div className={css.eachSlide} key={id}>
              <div className={css.imageContainer}>
                <Link to={`/album/${firstAlbumId}`}>
                  <img
                    src={`${BASE_URL}/uploads/${filename}`}
                    draggable="false"
                    loading="lazy"
                    data-dark={isDarkImage(id)}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          ))}
        </Fade>
      </div>
    </>
  )
}

export default Slideshow
