import React, {useCallback, useRef} from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import {Helmet as Head} from 'react-helmet'
import {TITLE} from '../config'
import Routes from '../routes'
import ScrollUpButton from './scroll-up'

const App = () => {
  const containerRef = useRef()
  const scrollRef = useRef()

  const scrollTop = useCallback(() => {
    scrollRef.current.scrollIntoView({behavior: 'smooth'})
  }, [])

  return (
    <>
      <Head>
        <meta charSet="utf-8"/>
        <title>{TITLE}</title>
      </Head>
      <Router>
        <div ref={scrollRef} />
        <Routes />
        <ScrollUpButton
          containerRef={containerRef}
          scrollTop={scrollTop}
        />
      </Router>
    </>
  )
}

export default App
