import {useEffect} from 'react'

export default function useOutsideClick(ref, cb) {
  useEffect(() => {
    const handleClickOutside = event => ref.current && !ref.current.contains(event.target) && cb()

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, cb])
}
