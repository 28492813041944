import React, {useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import classNames from 'classnames'
import {SLIDESHOW_ID} from '../../config'
import useSwipe from '../../hooks/use-swipe'
import useOutsideClick from '../../hooks/use-outside-click'
import logo from '../../assets/logo.png'
import iconBurger from './assets/icon-burger.svg'
import css from './style.mobile.module.scss'

const AlbumsListMobile = ({albums, albumId}) => {
  const containerRef = useRef(null)
  const [open, setOpen] = useState(false)

  const menuToggle = () => setOpen(!open)
  const menuOpen = () => setOpen(true)
  const menuClose = () => setOpen(false)

  useOutsideClick(containerRef, menuClose)
  useSwipe({onSwipeLeft: menuClose, onSwipeRight: menuOpen})

  if (!albums.length) return <div />

  return (
    <div
      ref={containerRef}
      className={css.albumsListWrapper}
    >
      {!open && (
        <button
          onClick={menuToggle}
          className={css.mobileMenuButton}
        >
          <img src={iconBurger} alt="" />
        </button>
      )}
      {open && (
        <div className={css.albumsListContainer}>
          <div className={css.logo}>
            <Link to={'/'}>
              <img src={logo} width="260" height="100" alt="TOYSHIKU" />
            </Link>
          </div>
          {albums.map(({_id: id, title}) => id !== SLIDESHOW_ID && (
            <Link
              onClick={menuClose}
              to={`/album/${id}`}
              key={id}
              className={classNames(albumId === id && css.active)}
            >
              {title}
            </Link>
          ))}
          {/* @TODO: Add */}
          {/*<Contacts type="inner" />*/}
        </div>
      )}
    </div>
  )
}

export default AlbumsListMobile
