import React, {memo} from 'react'
import css from './style.module.scss'
import {PHONE} from './constants'

const Phone = () => {
  return (
    <div className={css.phone}>
      <a
        className={css.phoneLink}
        href={`tel:${PHONE.value}`}
      >
        {PHONE.text}
      </a>
    </div>
  )
}

export default memo(Phone)
